<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Promotions" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
      </div>
      <div class="filter">
        <md-menu md-size="medium" class="">
          <md-button md-menu-trigger class="md-primary button-icon">
            {{
              category ? allPromotions.categories[category] : "Filter Category"
            }}
            <img src="@/assets/svg/filter.svg" />
          </md-button>

          <md-menu-content>
            <md-menu-item @click="filterPromotion('')">All</md-menu-item>
            <md-menu-item
              v-for="(category, index) in allPromotions.categories"
              :key="index"
              @click="filterPromotion(index)"
              >{{ category }}</md-menu-item
            >
          </md-menu-content>
        </md-menu>
      </div>
      <md-table
        v-model="allPromotions.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item, index }"
          :class="{ 'table-highlight': item.promote_count > 0 }"
        >
          <md-table-cell md-label="S/N" md-numeric>{{
            index + 1
          }}</md-table-cell>
          <md-table-cell md-label="Account Name"
            >{{ item.user.first_name }} {{ item.user.last_name }}</md-table-cell
          >
          <md-table-cell md-label="Type">{{
            item.category.title
          }}</md-table-cell>
          <md-table-cell md-label="Items"
            >{{ item.promotable[getAccessor(item.category.id)] }}
          </md-table-cell>
          <md-table-cell md-label="Payment Status">
            <div
              class="badge"
              :class="{
                success: item.payment_status == 'paid',
                danger: item.payment_status == 'unpaid',
              }"
            >
              {{ item.payment_status }}
            </div></md-table-cell
          >

          <md-table-cell md-label="Actions">
            <md-menu md-size="medium" md-align-trigger>
              <md-button :disabled="updating" md-menu-trigger class="bggreen"
                >Menu</md-button
              >

              <md-menu-content>
                <div v-if="hasPermission">
                  <md-menu-item
                    v-if="item.payment_status == 'paid'"
                    @click="changePaymentStatus(item.id, 'unpaid')"
                    >Make as Unpaid</md-menu-item
                  >
                  <md-menu-item
                    v-else
                    @click="changePaymentStatus(item.id, 'paid')"
                    >Make as Paid</md-menu-item
                  >
                  <md-menu-item @click="promote(item, 'unpaid')"
                    >Update Promotion
                  </md-menu-item>
                  <md-menu-item @click="remove(item.id)">Delete</md-menu-item>
                </div>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="allPromotions.mdPage"
        :records="allPromotions.mdCount"
        :per-page="allPromotions.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("promotion");
export default {
  name: "Promotions",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      time: null,
      user: null,
      portfolio: null,
      show: false,
      category: null,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions([
      "getPromotions",
      "updatePromotionPayment",
      "removePromotion",
    ]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getPortfolios(data);
    },
    setPortfolio(portfolio) {
      this.show = true;
      this.portfolio = portfolio;
    },
    async filterPromotion(val) {
      this.category = val;

      await this.getPromotions({
        category: val,
        keyword: this.search,
      });
    },
    async submitSearch() {
      await this.getPromotions({ keyword: this.search });
    },
    async changePaymentStatus(id, status) {
      try {
        const conf = confirm("Are you sure?");
        if (conf) {
          this.updating = true;
          await this.updatePromotionPayment({ payment_status: status, id });
          this.updating = false;
          this.toast("success", "Promotion Status Updated ");
          this.getPromotions();
        }
      } catch (err) {
        this.updating = false;
        console.error(err);
      }
    },
    async promote(item, mode = "create") {
      this.$eventBus.$emit("promote", {
        item_type: item.category.id,
        item_id: item.id,
        title: item.title,
        promote: item.promotable,
        mode,
      });
    },
    setUser(user) {
      this.show = true;
      this.user = user;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async remove(id) {
      try {
        const conf = confirm("Are you sure?");
        if (conf) {
          this.deleting = true;
          await this.removePromotion(id);
          this.deleting = false;
          this.toast("success", "Promotion Deleted");
        }
      } catch (err) {
        this.deleting = false;

        this.toast("error", "Opps, something went wrong");
      }
    },
    getAccessor(categoryId) {
      if (
        [this.promotions.HANDYMAN, this.promotions.PROFESSIONAL].includes(
          categoryId
        )
      ) {
        return "company_name";
      } else if ([this.promotions.ACCOUNT].includes(categoryId)) {
        return "full_name";
      } else {
        return "title";
      }
    },
  },
  created() {},
  mounted() {
    console.log("Promotions");
    this.getPromotions();
  },
  computed: {
    ...mapGetters(["allPromotions", "loading"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.filter {
  display: flex;
  justify-content: end;
  margin-right: 50px;
}
</style>
